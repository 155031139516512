import { useEffect } from "react";
import { getStudioJwts } from "@/queries/auth/get-studio-session";
import { useAuthSession } from "../use-auth-session";
import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/navigation";
import { appConfig } from "@/configs";

export function useStudioTokens() {
  return useMutation({
    mutationFn: getStudioJwts,
  });
}

const { authServiceFeUrlWithRedirect } = appConfig;

export function useStudioSession() {
  const router = useRouter();

  const {
    idToken,
    isLoading: isAuthSessionPending,
    isError: isAuthSessionError,
  } = useAuthSession();

  if (isAuthSessionError) {
    router.push(authServiceFeUrlWithRedirect);
  }

  const {
    mutate,
    data: studioSession,
    isPending: isStudioSessionPending,
    isError: isStudioSessionError,
  } = useStudioTokens();

  useEffect(() => {
    if (idToken && !isAuthSessionPending) {
      mutate({ idToken, orgId: localStorage.getItem("currentOrg") as string });
    }
  }, [idToken, isAuthSessionPending]);

  function getNewStudioToken(orgId: string) {
    mutate(
      { idToken, orgId },
      {
        onSuccess: () => {
          router.push("/channels");
          router.refresh();
        },
      }
    );
  }

  return {
    studioSession,
    isStudioSessionPending,
    isStudioSessionError,
    getNewStudioToken,
  };
}
