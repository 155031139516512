import { GetAuthSession200 } from "@/api/models";

type GetStudioJwtPayload = {
  idToken: string;
  orgId?: string;
};

export async function getStudioJwts(
  payload: GetStudioJwtPayload
): Promise<GetAuthSession200> {
  try {
    const { idToken, orgId } = payload;
    const response = await fetch(`/api/auth${orgId ? `?orgId=${orgId}` : ""}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch JWTs");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching JWTs:", error);
    throw error;
  }
}
