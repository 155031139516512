export interface AppConfig {
  auth: {
    service: string;
    frontend: string;
    autoRefreshSession: boolean;
    autoSyncSession: boolean;
    debug: boolean;
  };
  environment: "development" | string;
  authSSOLoginUrl: string;
  apiUrl: string;
  serviceBaseUrl: string;
  authServiceFeUrlWithRedirect: string;
}

export const appConfig: Readonly<AppConfig> = Object.freeze({
  auth: {
    service: process.env.NEXT_PUBLIC_AUTH_SERVICE_BASE_URL,
    frontend: process.env.NEXT_PUBLIC_AUTH_APP_BASE_URL,
    autoRefreshSession: process.env.NEXT_PUBLIC_AUTH_REFRESH_SESSION === "true",
    autoSyncSession: process.env.NEXT_PUBLIC_AUTH_SYNC_SESSION === "true",
    debug: process.env.NEXT_PUBLIC_AUTH_DEBUG === "true",
  },
  environment: "staging",
  authSSOLoginUrl: `${process.env.NEXT_PUBLIC_AUTH_SSO_LOGIN_URL}`,
  apiUrl: `${process.env.NEXT_PUBLIC_API_URL}`,
  serviceBaseUrl: `${process.env.NEXT_PUBLIC_STUDIO_REGION_SERVICE_BASE_URL}`,
  authServiceFeUrlWithRedirect: `${process.env.NEXT_PUBLIC_AUTH_APP_BASE_URL}?redirect=${process.env.NEXT_PUBLIC_BASE_URL}`,
} as AppConfig);
