import { useQuery } from "@tanstack/react-query";
import { getAuthSession } from "@/queries/auth/get-auth-session";

export function useAuthSessionData() {
  return useQuery({
    queryKey: ["auth-session"],
    queryFn: getAuthSession,
    refetchOnReconnect: true,
    staleTime: 25 * 60 * 1000,
    // refetchOnWindowFocus: true,
  });
}

export const useAuthSession = () => {
  const { data, isLoading, isError } = useAuthSessionData();
  return {
    idToken: data?.token as string,
    isLoading: isLoading,
    isError,
  };
};
