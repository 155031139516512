import { useState, useEffect } from "react";
import { OrgListOrg } from "@/types/org.types";
import { useStudioSession } from "@/hooks/auth/use-studio-session";

export interface UseOrgsReturnType {
  allOrgs: OrgListOrg[] | null;
  changeOrg: (orgId: string) => void;
  isStudioSessionPending: boolean;
  studioSession: any | null;
}

export function useOrgs(): UseOrgsReturnType {
  const [allOrgs, setAllOrgs] = useState<OrgListOrg[] | null>(null);

  const { studioSession, isStudioSessionPending, getNewStudioToken } =
    useStudioSession();

  useEffect(() => {
    if (studioSession && !isStudioSessionPending) {
      setAllOrgs(studioSession.allOrgs ?? null);
    }
  }, [studioSession, isStudioSessionPending]);

  async function changeOrg(orgId: string) {
    localStorage.setItem("currentOrg", orgId);
    getNewStudioToken(orgId);
  }

  return {
    allOrgs,
    changeOrg,
    isStudioSessionPending,
    studioSession,
  };
}
