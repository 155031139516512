import { AuthManager } from "@screencloud/auth-sdk";

export const createAuthManager = () => {
  return new AuthManager({
    debug: process.env.NEXT_PUBLIC_AUTH_DEBUG === "true",
    autoRefresh: false,
    autoSync: true,
    service: { url: `${process.env.NEXT_PUBLIC_AUTH_SERVICE_BASE_URL}` },
    frontend: { url: `${process.env.NEXT_PUBLIC_AUTH_APP_BASE_URL}` },
  });
};
