"use client";
import React, { createContext, FC, useEffect } from "react";
import { OrgListOrg } from "@/types/org.types";
import { useOrgs } from "@/hooks/auth/use-orgs";
import { usePathname, useRouter } from "next/navigation";
import { createAuthManager } from "./helpers/create-auth-manager";
import { appConfig } from "@/configs";

const { authServiceFeUrlWithRedirect } = appConfig;

interface AuthContextValue {
  allOrgs: OrgListOrg[] | null;
  studioSession: any | null;
  isLoading: boolean;
  logout: () => void;
  changeOrg: (orgId: string) => void;
}

export const AuthContext = createContext<AuthContextValue>({
  allOrgs: [],
  studioSession: null,
  isLoading: true,
  logout: () => {},
  changeOrg: () => {},
});

export const AuthContextProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const pathname = usePathname();

  const { studioSession, allOrgs, changeOrg, isStudioSessionPending } =
    useOrgs();

  useEffect(() => {
    if (!isStudioSessionPending) {
      if (!localStorage.getItem("currentOrg")) {
        router.push("/org");
      } else if (studioSession) {
        if (pathname === "/") {
          router.push("/channels");
        }
      }
    }
  }, [isStudioSessionPending, studioSession, pathname, router]);

  async function logout() {
    const authManager = createAuthManager();
    await authManager.logout();
    router.push(authServiceFeUrlWithRedirect);
  }

  return (
    <AuthContext.Provider
      value={{
        logout,
        studioSession,
        allOrgs,
        changeOrg,
        isLoading: isStudioSessionPending,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
