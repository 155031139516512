"use client";
import React from "react";
import { AuthContextProvider } from "@/contexts/auth-context";
import QueryClientProvider from "@/contexts/query-client-provider";

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider>
      <AuthContextProvider>{children}</AuthContextProvider>
    </QueryClientProvider>
  );
}
