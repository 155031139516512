export async function getAuthSession(): Promise<any> {
  const resp = await fetch(
    `${process.env.NEXT_PUBLIC_AUTH_SERVICE_DOMAIN}/token/refresh`,
    {
      cache: "no-store",
      credentials: "include",
    }
  );

  return resp.json();
}
